class GlideSlider {
  constructor(element, ...options) {
    this.defaults = {
      container: '.js-glide-slider',
      type: 'carousel',
      gap: 16,
      animationDuration: 300,
      classes: {
        direction: {
          ltr: 'is-ltr',
          rtl: 'is-rtl',
        },
        slider: 'is-slider',
        carousel: 'is-carousel',
        swipeable: 'is-swipeable',
        dragging: 'is-dragging',
        cloneSlide: 'is-clone',
        activeNav: 'is-active',
        activeSlide: 'is-active',
        disabledArrow: 'is-disabled',
      },
    };

    this.element = element;
    this.options = Object.assign({}, this.defaults, ...options);

    this.init();
  }

  init() {
    this.slider = new Glide(this.element, this.options).mount();
  }
}
