(function () {
  /*
   * graceProduce('.js-selector', ClassName, options)
   *
   * to get a instance of the Module ClassName for each DOM element
   * Factory Pattern
   */

  graceProduce('.js-portfolio-slider', GlideSlider, {
    perView: 4.25,
    focusAt: 'center',
    breakpoints: {
      700: {
        perView: 1.3,
      },
    },
  });

  /*
   * graceCreate('.js-selector', ClassName, options)
   *
   * to get one instance of the Module ClassName with each DOM element
   * Singleton Pattern
   */
}());
